import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('auth')
export default {
  computed: {

    ...mapGetters(['user', 'generalConfigs', 'isTikTokAppCenter']),

    isFromTikTok() {
      return this.isTikTokAppCenter || this.user?.data?.registerFromService === 'TTAC'
    },

    isOnlyEcomdyPlatform() {
      return this.isEcomdyPlatform && !this.isExclusivePartner
    },

    isExclusivePartner() {
      return this.generalConfigs?.system === 'ADREACH'
    },

    companyName() {
      return process.env.VUE_APP_COMPANY_NAME
    },

    platformName() {
      return process.env.VUE_APP_PLATFORM_NAME
    },

    isEcomdyPlatform() {
      return this.platformName === 'Ecomdy'
    },

    isPATIPlatform() {
      return this.platformName === 'PATI'
    },

    isSMBAgencyPlatform() {
      return this.platformName === 'SBH Media'
    },

    isDC3Platform() {
      return this.platformName === '3 Độ Agency'
    },

    isEmediaPlatform() {
      return this.platformName === 'Emedia'
    },

    isTeeXcorpPlatform() {
      return this.platformName === 'TeeXcorp'
    },

    isGyxenPlatform() {
      return this.platformName === 'Gyxen'
    },

    isGoodvibesPlatform() {
      return this.platformName === 'Goodvibes Agency'
    },

    isFinalArrowPlatform() {
      return this.platformName === 'Final Arrow'
    },

    logo() {
      // eslint-disable-next-line global-require
      return process.env.VUE_APP_LOGO || require('@/assets/images/logo/logo.svg')
    },

    listMarket() {
      return process.env.VUE_APP_LIST_MARKET
    },

    /* ----- Docs ----*/

    servicePolicy() {
      return process.env.VUE_APP_SERVICE_POLICY
    },

    refundPolicy() {
      return process.env.VUE_APP_REFUND_POLICY
    },

    guideVerifyAccount() {
      return process.env.VUE_APP_GUIDE_TO_VERIFY_ACCOUNT
    },

    guideToTopUpBUSD() {
      return process.env.VUE_APP_GUIDE_TO_TOP_UP_BUSD
    },

    guideToTopUpAirwallex() {
      return process.env.VUE_APP_GUIDE_TO_TOP_UP_AIRWALLET
    },

    guideToTopUpLianLian() {
      return process.env.VUE_APP_GUIDE_TO_TOP_UP_LIANLIAN
    },

    guideToTopUpCreditCard() {
      return process.env.VUE_APP_GUIDE_TO_TOP_UP_CREDIT_CARD
    },

    activeYourCreditCard() {
      return process.env.VUE_APP_ACTIVE_YOUR_CREDIT_CARD
    },

    guideToTopUpPayoneer() {
      return process.env.VUE_APP_GUIDE_TO_TOP_UP_PAYONEER
    },

    guideToTopUpPaypal() {
      return process.env.VUE_APP_GUIDE_TO_TOP_UP_PAYPAL
    },

    guideToTopUpTazapay() {
      return process.env.VUE_APP_GUIDE_TO_TOP_UP_TAZAPAY
    },

    guideToTopUpUSDT() {
      return process.env.VUE_APP_GUIDE_TO_TOP_UP_USDT
    },

    guideToTopUpTransferWise() {
      return process.env.VUE_APP_GUIDE_TO_TOP_UP_WISE
    },

    guideToTopUpPayfast() {
      return process.env.VUE_APP_GUIDE_TO_TOP_UP_PAYFAST
    },

    guideToConnectTikTokAccount() {
      return process.env.VUE_APP_GUIDE_TO_CONNECT_TIKTOK_ACCOUNT
    },

    guideToCreateBusinessCenter() {
      return process.env.VUE_APP_GUIDE_TO_CREATE_BUSINESS_CENTER
    },

    guideToCreateAdAccount() {
      return process.env.VUE_APP_GUIDE_TO_CREATE_AD_ACCOUNT
    },

    guideToSubmitProductLink() {
      return process.env.VUE_APP_GUIDE_TO_SUBMIT_PRODUCT_LINK
    },

    guideToCancelSubscription() {
      return process.env.VUE_APP_GUIDE_TO_CANCEL_SUBSCRIPTION
    },

    guideToUpgradeSubscription() {
      return process.env.VUE_APP_GUIDE_TO_UPGRADE_SUBSCRIPTION
    },

    guideToDownloadInvoiceTopUp() {
      return process.env.VUE_APP_GUIDE_TO_DOWNLOAD_INVOICE_TOP_UP
    },

    guideToDownloadInvoiceSubscription() {
      return process.env.VUE_APP_GUIDE_TO_DOWNLOAD_INVOICE_SUBSCRIPTION
    },

    guideToAutoTopUpAdAccount() {
      return process.env.VUE_APP_GUIDE_TO_AUTO_TOP_UP_AD_ACCOUNT
    },

    autoTopUpAdAccountPolicy() {
      return process.env.VUE_APP_AUTO_TOP_UP_AD_ACCOUNT_POLICY
    },

    autoRefundPolicy() {
      return process.env.VUE_APP_AUTO_REFUND_POLICY
    },

    guideToTopUpLocalBank() {
      return process.env.VUE_APP_GUIDE_TO_TOP_UP_LOCAL_BANK
    },

    emailSupportPartnero() {
      return process.env.VUE_APP_PARTNERO_EMAIL_URL
    },

    whatsAppSupportPartnero() {
      return process.env.VUE_APP_PARTNERO_WHATS_APP_URL
    },

    larkSupportPartneroUrl() {
      return process.env.VUE_APP_PARTNERO_LARK_URL
    },

    facebookSupportPartneroUrl() {
      return process.env.VUE_APP_PARTNERO_FACEBOOK_URL
    },

    discordUrl() {
      return process.env.VUE_APP_DISCORD
    },

    telegramUrl() {
      return process.env.VUE_APP_TELEGRAM
    },

    instagramUrl() {
      return process.env.VUE_APP_INSTAGRAM
    },

    messengerUrl() {
      return process.env.VUE_APP_MESSENGER
    },

    whatsAppUrl() {
      return process.env.VUE_APP_WHATS_APP
    },

    termsOfService() {
      return process.env.VUE_APP_GUIDE_TERMS_OF_SERVICE
    },

    /* ----- Download invoice ----*/
    hasShowInvoice() {
      return process.env.VUE_APP_SHOW_INVOICE === 'true'
    },

    /* ----- End Docs ----*/

    hasMaintainMode() {
      return process.env.VUE_APP_MAINTAIN_MODE === 'true' && !this.isExclusivePartner
    },

    // handle show/hide feature and page
    trialMode() {
      return process.env.VUE_APP_MODE_TRIAL === 'true'
    },

    subscriptionMode() {
      return process.env.VUE_APP_MODE_SUBSCRIPTION === 'true'
    },

    tipMode() {
      return process.env.VUE_APP_MODE_TIP === 'true'
    },

    refMode() {
      return process.env.VUE_APP_MODE_REF === 'true'
    },

    partnerShipMode() {
      return process.env.VUE_APP_MODE_PARTNERSHIP === 'true'
    },

    pixelMode() {
      return process.env.VUE_APP_MODE_PIXEL === 'true'
    },

    isVerifyEmailLater() {
      return process.env.VUE_APP_MODE_VERIFY_EMAIL === 'true'
    },

    showUserGuide() {
      return process.env.VUE_APP_SHOW_USER_GUIDE === 'true'
    },

    productLinkMode() {
      return process.env.VUE_APP_MODE_PRODUCT_LINK === 'true'
    },

    autoTopUpAdAccountMode() {
      return process.env.VUE_APP_MODE_AUTO_TOPUP_AD_ACCOUNT === 'true'
    },

    refundMode() {
      return process.env.VUE_APP_MODE_REFUND === 'true'
    },

    refundAutoMode() {
      return process.env.VUE_APP_MODE_REFUND_AUTO === 'true' && !this.isExclusivePartner
    },

    affiliateProgramMode() {
      return process.env.VUE_APP_PARTNERO_ID
    },

    // navbar
    showAppHelpCenterComponent() {
      return process.env.VUE_APP_SHOW_APP_HELP_CENTER === 'true'
    },

    loginUrl() {
      return process.env.VUE_APP_LOGIN_URL
    },

    partneroLoginUrl() {
      return process.env.VUE_APP_PARTNERO_LOGIN_URL
    },

    isShowNotification() {
      return process.env.VUE_APP_NOTIFICATION === 'true'
    },
  },

  methods: {
    goToMessengerUrl() {
      window.open(this.messengerUrl)
    },

    goToDiscordUrl() {
      window.open(this.discordUrl)
    },

    goToTelegramUrl() {
      window.open(this.telegramUrl)
    },

    goToInstagramUrl() {
      window.open(this.instagramUrl)
    },

    goToWhatsApp() {
      window.open(this.whatsAppUrl)
    },
  },
}
